import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import LightHeader from "../components/headers/lightHeader"
import { Helmet } from "react-helmet"
import ReactTooltip from "react-tooltip"
import { calcVhFromPixels, calcVwFromPixels } from "../helpers/general"

export const query = graphql`
  {
    allSanityPage {
      nodes {
        id
        title
        slug {
          current
        }
        frontImage {
          asset {
            id
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
        locy
        locx
        width
        zindex
        mix
        _rawMainContent
      }
    }
  }
`

const pagesWithFrontImages = queryData => {
  const nodeArray = queryData.allSanityPage.nodes
  const onlyFrontImages = nodeArray.filter(node => node.frontImage !== null)

  const onlyWithAssets = onlyFrontImages.filter(
    node => node.frontImage.asset != null
  )
  return onlyWithAssets
}

const IndexPage = ({ data }) => {
  const frontImagePages = pagesWithFrontImages(data)
  const transparencyAnimation = " hover:opacity-50"

  const ImageCanvas = frontImagePages.map(page => {
    const extraClasses =
      page.id === "4c3300b0-764f-5f9d-ae24-0ce92c09b994" ||
      page.id === "d52dea20-9433-5bb4-b0a3-9989cb20d08d"
        ? ""
        : transparencyAnimation

    return (
      <>
        <Link to={`/${page.slug.current}/`}>
          <div
            data-tip={`${page.title}`}
            className={` fixed ${extraClasses} transition duration-1000 ease-in-out transform hover:scale-125 pt-24 md:pt-0`}
            style={{
              top: calcVhFromPixels(page.locy),
              left: calcVwFromPixels(page.locx),
              width: calcVwFromPixels(page.width),
              mixBlendMode: page.mix,
              zIndex: page.zindex ? page.zindex : 10,
              cursor: "none",
            }}
          >
            <Img fluid={page.frontImage.asset.fluid} />
          </div>
        </Link>
      </>
    )
  })

  return (
    <>
      <div className="w-screen h-screen" style={{ backgroundColor: "#F5F5F5" }}>
        <Helmet>
          <style>{"body { background-color: ; }"}</style>
          <title>SLOWGLASS</title>
        </Helmet>
        <LightHeader height={32} />
        <div className="pt-32">{ImageCanvas}</div>
      </div>
      <ReactTooltip
        className="customtt font-regular w-56 text-xl"
        backgroundColor="transparent"
        textColor="black"
        offset="{'bottom':40}"
      />
    </>
  )
}

export default IndexPage
